import React from "react"
import Container from "../Container"
import row from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "bootstrap/dist/css/bootstrap.min.css";
import { faPieChart, faUsers, faMobile } from '@fortawesome/free-solid-svg-icons'
import ai from '../../assets/images/city_1.png'
import leftdeco from '../../assets/images/subsi-ring.webp'
import aitrader from '../../assets/images/utopos_img_01.png'
import globe from '../../assets/images/globe.webp'
import utop_user from '../../assets/images/utopo_user.png'
import glow_lines from '../../assets/images/glow-lines.webp'
const features = [
    {
        name: 'International free trade zone',
        description: 'The City is planning to apply for several preferential designations including status as an international free tradezone. We are also seeking certifications regarding best practices for sustainable communities.',
        href: 'https://www.d.com',

    },
    {
        name: 'Utopos Metaverse',
        description: 'Utopos metaverse allow users to explore the city virtually, buy and sell land and operate business in a digital space through the "Dirt" layer that is powered by the ERTH Token.',
        href: '/wallet',

    },
    {
        name: 'Virtual Reality',
        description: "Virtual Reality technology in allows you to inspect properties under constructions, explore land and real-estate properties with high-res VR visuals, inspections are easy.",
        href: '/metaverse',
    },
    {
        name: 'Virtual Tourism',
        description: "Virtual tourism in Utopos City allow everyone in the real-world to explore important touristic sites in the virtual city through high resolution Virtual reality glasses",
        href: '/vehron-launchpad',

    },
    {
        name: 'V-Gigs & V-Lancing',
        description: 'From an impresive 3D teleconferencing capability, to the world\'s most advanced VR platform for freelancers and gigworkers across all technical and creative fields, Utopos City makes life easy.',
        href: '#',

    },
    {
        name: 'Virtual Gaming',
        description: 'From GTA-style vehicle chases and shootouts to treasure hunts and other crowd events, Utopos city will be the  world’s premium site for hyper-realistic v-gaming',
        href: '#',

    },
    {
        name: 'The Digital Wild West',
        description: 'With blockchain-backed security and privacy, Blockchain City offers an ultimate 3D virtual relationship and dating community.',
        href: '#',

    },

    {
        name: 'IoT & IoB Support ',
        description: 'Fueled by AI blockchain, VR, and robotic technologies, the City will be attestbed for developing a comprehensive Internet of Things to cover an entire urban area',
        href: '#',

    },

]
export default function SectionThree(props) {

    return (
        <>
            <section className="promo-section ptb-120  feature-section bg-[conic-gradient(at_right,_var(--tw-gradient-stops))] from-white via-white to-card-secondary/10 ptb-120">
                <Container>
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9 pb-4">
                            <div className="section-headings text-center" data-aos="fade-up">
                                <h2 className="font-bold tracking-tight font-arimo text-card-primary text-4xl md:text-5xl">The World's First Smart City Powered by Blockchain Technology.</h2>
                                <p class="text-dark">The world's first smart city powered by blockchain technology future planned being developed on an approximately 50,000-acre site located globally, with suitable access and available utilities such as water and electricity. The precise location of the target site has not yet been disclosed due to competitive concerns during the real estate acquisition process.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-6">
                        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
                            <div className="bg-white p-5 text-center d-flex  flex-column h-100 rounded-custom" data-aos="fade-up" data-aos-delay="100">
                                <div className="promo-card-info mb-4">
                                    <h3 className=" text-slate-800 absolute mt-[-65px] ml-5 py-1 text-lg font-bold px-4 rounded-full bg-green-400">Layer 1: Utopos Token</h3>
                                    <h3 className="display-5 fw-bold mb-4 text-white t2-grad"> $UTOP
                                    </h3>
                                    <p className="text-card-primary mb-3">Fungible, collectible novelty token used for transactions within the city and worldwide.</p>
                                    <div className="mt-3">
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
                            <div className="bg-white p-5 text-center d-flex flex-column h-100 rounded-custom  border-spacing-1 border-card" data-aos="fade-up" data-aos-delay="150">
                                <div className="promo-card-info mb-4">
                                    <h3 className=" text-white absolute mt-[-65px] ml-5 py-1 text-lg font-bold px-4 rounded-full bg-card">Layer 2: Earth Token</h3>
                                    <h3 className="display-5 fw-extrabold mb-4 text-card">$ERTH
                                    </h3>
                                    <p className="text-card-primary">A non-fungible, collectible novelty token that controls ownership of each square meter of the city</p>
                                    <div className="mt-3">

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0">
                            <div className="bg-white p-5 text-center d-flex flex-column h-100 rounded-custom" data-aos="fade-up" data-aos-delay="200">
                                <div className="promo-card-info mb-4">
                                    <h3 className=" text-white absolute mt-[-65px] ml-5 py-1 text-lg font-bold px-4 rounded-full bg-purple-600">Layer 3: Admin Token</h3>
                                    <h3 className="display-5 fw-bold mb-4 text-spring">
                                        $SERVE</h3>
                                    <p className="text-card-primary">
                                        This is an administrative token used to govern smart contracts and other digital interactions between Earth and Utopos token owners.
                                    </p>
                                    <div className="mt-3">
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </Container>
            </section>
            <section className="feature-section-two ptb-120 bg-card-primary">
                <Container>


                    <div className="row  align-items-center justify-content-between">
                        <div className="col-lg-6 col-md-12 z-10">
                            <div className="section-heading" data-aos="fade-up">
                                <h2 className="text-white font-medium tracking-tight font-arimo md:text-5xl text-4xl">Futuristic City Design with Smart, Eco-Friendly Building Construction</h2>
                                <p className="text-white">The futuristic design of Utopos incorporates smart and eco-friendly building construction, featuring contemporary homes, apartment buildings, and offices as well as estates, university schools, retail and wholesale centers, and high-tech industrial parks. Special incentives are available for sustainable industrial development, including digital and real agriculture and solar farming</p>
                                <h4 className="text-white font-sans text-xl">To ensure efficient transportation throughout the city, Utopos will feature strategically placed autonomous electric vehicles and drones. Hyperloop technology will be used for fast and reliable transport, while the city will also have a planned airport/spaceport and several heliports, as well as multiple drone ports.
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-7">
                            <div className="feature-img-wrap position-relative d-flex flex-column align-items-end">

                                <img src={aitrader} alt="feature" className="img-fluid rounded-custom" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="feature-section bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-white via-[#e8dbff] to-white ptb-120">
                <Container>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6 col-md-6">
                            <div className="image-wrap mb-5 mb-md-0 mb-lg-0 mb-xl-0  p-2 rounded-lg" data-aos="fade-up">
                                <img src={ai} alt="feature img" className="img-fluid" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="feature-content-wrap" data-aos="fade-up" data-aos-delay="50">
                                <h2 class="text-capitalize font-bold font-arimo text-card-primary tracking-tight md:text-5xl text-4xl">
                                    Utopos City is Built on 3 Conceptual Layers</h2><p class="text-dark">The City uses a three-token Polygon-compliant system. It’s scalable and flexible for the possibility of migrating to a proprietary new blockchain / hard fork.
                                </p>
                            </div>
                            <div class="layers">
                                <h3 class="font-medium text-[20px] text-dark ">
                                    <span class="t2-grad text-4xl"> 01. Dirt </span> -Earth Token (ERTH)</h3>
                                <p class="text-sm text-dark">Earth is a non-asset-backed, non-fungible token(NFT) compliant with the ERC-721 standard. Earth represents ownership of a fractional, digitized 99-year lease on the land underneath the City.</p>
                                <h3 class="font-medium text-[20px] text-dark">
                                    <span class=" t3-grad text-4xl"> 02. Digital</span>- Utopos Token (UTOP) </h3>
                                <p class="text-dark text-sm">UTOP is the native token for the digital layer, usable for city-to-city trade with other smart cities worldwide. $UTOP is designed to track the performance of underlying business, regardless of whether they're transacting with Ether or other distributed-ledger systems.</p>
                                <h3 class="font-medium text-dark text-[20px]">
                                    <span class="t1-grad text-4xl">03. Administration</span>- Service Token (SERV) </h3>
                                <p class="text-sm text-dark">The Service Token governs the Administrative layer and will be used for Consensus &amp; voting, referendum fees, staking and many other applications.</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="work-process ptb-120 bg-white">
                <Container>
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-8 col-md-10">
                            <div className="section-heading text-center" data-aos="fade-up">
                                <h4 className="text-white t1-grad font-arimo font-semibold text-[18px] uppercase tracking-wider">Benefits & Incentives</h4>
                                <h2 className="text-capitalize font-extrabold tracking-tight text-forest font-arimo md:text-5xl text-4xl">Experience The Future, Live In The Future With Utopos City.</h2>
                                <p className="text-white">Utopos blockchain city offers a unique experience that blends benefits from both the real and virtual worlds. This combined lifestyle provides complete freedom and privacy.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 order-1 order-lg-0">
                            <div className="img-wrap" data-aos="fade-up" data-aos-delay="50">
                                <img src={utop_user} alt="work process" className="img-fluid rounded-custom" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 order-0 order-lg-1">
                            <h2 className="text-forest font-bold tracking-tight font-arimo text-4xl md:text-5xl">Benefits and Incentives to Members</h2>
                            <p className="text-slate-800">Utopos offers strategic benefits for businesses, particularly for e-commerce and website owners. With ownership of Earth Token ($ERTH) anywhere in the city, business owners worldwide can establish both business and personal residency in globally.</p>

                        </div>
                    </div>
                </Container>
            </section>
            <section className="integration-section bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-white via-[#efe7fe] to-white  ptb-120">
                <Container>
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-lg-6 col-md-12">
                            <div className="section-heading">
                                <h4 className="h5 text-spring tracking-wider font-arimo t1-grad">KEY FEATURES</h4>
                                <h2 className="tracking-tight text-card-primary font-bold font-arimo md:text-5xl text-4xl">Key Features of UTOP Platform</h2>
                                <p className="text-dark">Now is the time to build a new economic system from the ground up, one city at a time, based on blockchain and cryptocurrency. Blockchain technology offers new hope for individuals, businesses, and communities.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="text-lg-end mb-5 mb-lg-0">
                                <img src={globe} alt="platform" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="integration-wrapper position-relative w-100">
                                <ul className="integration-list list-unstyled mb-0">

                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="cta-subscribe bg-[#f0e6fe]   text-white ptb-120 position-relative overflow-hidden">

                <Container className="z-10">
                    <div className="flex flex-wrap text-center">
                        {features.map((item) => (
                            <div className="md:w-3/12 w-1/2 z-10 my-2">
                                <div className="py-[1px] md:px-[1px] px-[2px] rounded-lg   bg-[radial-gradient(ellipse_at_bottom_left,_var(--tw-gradient-stops))] from-spring/75 via-[#6c14e8] to-spring m-2 h-full justify-center align-middle">
                                    <div className="bg-black/10 h-full rounded-lg md:p-3 p-2">
                                        <h1 className="font-bold text-[15px] uppercase font-arimo pt-4">{item.name}</h1>
                                        <p className="text-[14px]">{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>

            </section>
            <section className="cta-subscribe bg-white  text-black ptb-120 position-relative overflow-hidden">
                <Container>
                    <div className="row ">
                        <div className="col-md-10 mx-auto text-center bg-spring/10 py-6 rounded-[60px] border-v border-sky-100">
                            <h5 className="text-spring font-arimo text-center">Beta Version</h5>
                            <h3 className="text-4xl text-card-primary font-arimo font-bold md:text-5xl">Swap Crytocurrencies with Utoswap</h3>
                            <p className="text-dark">UtoSwap is a next-generation layer-2 decentralized exchange and Automated Market Maker that let's you swap MRC-20 tokens on Polygon(Matic) network, at near-zero gas fees.</p>
                            <div className=" py-8 justify-center align-middle mx-auto text-center items-center">
                                <a
                                    href="https://utoswap.com"
                                    className="rounded-xl jello-horizontal bg-spring px-8 py-3 text-md font-semibold leading-7 font-arimo text-white uppercase shadow-sm hover:bg-card focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Try UtoSwap Now
                                </a>
                            </div>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}