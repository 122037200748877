import classNames from "classnames";
import React from "react";
import { Row } from "react-bootstrap";
import Container from "../Container";
import Logo from '../../assets/images/logo.png'
export default function Footer(props) {
 
  return (
       
<footer className="bg-black ptb-120">
   <Container>
   <div className="md:flex md:justify-between vehron-foot">
        <div className="mb-6 md:mb-0 lg:w-5/12">
            <a href="/" className="flex items-center">
                <img src={Logo} className="h-8 mr-3" alt="FlowBite Logo" />
               
            </a>
            <p className="text-sm text-white my-3">Utopos aims to build the world's first futuristic, eco-friendly blockchain city where everyone can explore the city and perform everyday activities in a virtual space (Metaverse). Members earn rewards from various activities in UTOP, which is its native currency. Utopos blockchain city offers equal opportunities for everyone to own real estate and carry out constructions with ease. </p>
         
        </div>
        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-4">
            <div>
                <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white  font-slab">FEATURED</h2>
                <ul className="text-forest dark:text-gray-400">
                    <li >
                        <a href="https://utoswap.com/" className="hover:underline">UtoSwap</a>
                    </li>
                    <li>
                    
                        <a href="https://utopos.io/assets/utopos_whitepaper.pdf" className="hover:underline">Whitepaper</a>
                    </li>
                    <li >
                        <a href="/wallet" className="hover:underline">Utopos Wallet</a>
                    </li>
                </ul>
            </div>
            <div>
                <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white font-slab">Utilities</h2>
                <ul className="text-forest dark:text-gray-400">
                    <li>
                        <a href="/metaverse" className="hover:underline">Metaverse</a>
                    </li>
                    <li>
                        <a href="/utopos-token" className="hover:underline">UTOP Token</a>
                    </li>
                    <li>
                        <a href="/exchange" className="hover:underline">Exchange</a>
                    </li>
                    <li>
                        <a href="/humanitarian-hub" className="hover:underline">Humanitarian Hub</a>
                    </li>
                </ul>
            </div>
            <div>
                <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white font-slab">Communitties</h2>
                <ul className="text-forest dark:text-gray-400">
                    <li >
                        <a href="https://www.facebook.com/subsiblockchain" className="hover:underline ">Facebook</a>
                    </li>
                    <li >
                        <a href="https://twitter.com/subsi2b" className="hover:underline ">Twitter</a>
                    </li>
                    <li >
                        <a href="https://www.linkedin.com/company/subsiblockchain" className="hover:underline ">LinkedIn</a>
                    </li>
                    <li >
                        <a href="https://t.me/utoposofficialgroup" className="hover:underline ">Telegram</a>
                    </li>
                </ul>
            </div>
            <div>
                <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white font-slab">Policies</h2>
                <ul className="text-forest dark:text-gray-400">
                    <li>
                        <a href="#" className="hover:underline">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="#" className="hover:underline">Terms &amp; Conditions</a>
                    </li>
                    <li>
                        <a href="#" className="hover:underline">Disclaimer</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
   
   </Container>
  
</footer>

       
   
  );
}
