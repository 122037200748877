import Header from "../components/Layout//Header";
import Hero from "../components/Home/HeroSection";
import PromoSection from "../components/Home/PromoSection";
import SectionThree from "../components/Home/SectionThree";
import Footer from "../components/Layout/Footer";

export default function Home() {
  return (
    <>
      <Header />
      <Hero />
      <PromoSection />
      <SectionThree />
      <Footer />
    </>
  );
}
