import React from 'react'
import Container from '../Container'
import PromoSection from '../Home/PromoSection'
import leftdeco from '../../assets/images/subsi-ring.webp'
import globe from '../../assets/images/globes.webp'

export default function Content() {
  return (
   <>
        <div className="special-bg-02">
        <Container>
      
        
        <div className="row max-w-7xl justify-items-center align-items-center z-10">
            <div className="col-md-6  py-32 sm:py-48 lg:py-40 md:space-x-5 z-10">
                <div className="text-left">
                <h1 className="text-2xl text-white tracking-tight sm:text-6xl font-bold">
               <span className='text-white'>Utopos Token</span> Distribution Metrics</h1>

                <p className='text-white'>UTOP token is a collectible novelty, and it's used through out the City's ecosystem for buying and selling, e-commerce, payments, and all daily financial transactions.</p>
                <div className="mt-10 flex items-center justify-cd gap-x-6">
                <a
                    href="#"
                    className="px-6 py-3 rounded-2xl text-white bg-spring font-bold uppercase font-arimo hover:bottom_banner"
                    >
                    Swap, Earn, Exchange
                    </a>
                </div>
                </div>
            </div>
            <div className="col-md-6 max-w-2xl z-10">
                <div className="text-left">
                <img src="./assets/images/chart.png" alt="" width={550} />
                </div>
            </div>
        </div>
        </Container>
        </div>
       
        <section className="feature-promo bg-slate-100  ptb-120">          
            <Container>
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10">
                        <div className="section-heading text-center">
                            <h3 className='text-bold t1-grad'>Use Cases</h3>
                            <h2 className="tracking-tight font-bold text-4xl md:text-5xl text-card-primary">Use Cases for Owning Utopos Token ($UTOP)</h2>
                            <p className='text-dark'>Utopos is a non-asset-backed token compliant with the MRC-20 standard. The Utopos token is a collectible novelty, and it's used through out the City's ecosystem for buying and selling, e-commerce, payments, and all daily financial transactions.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-feature-promo p-lg-4 p-4 h-full  mt-3 bg-white shadow-sm rounded-3xl border-1 border-[#9807cd]">
                            <div className="feature-info-wrap">
                                <img src="./assets/images/credit-card.png" alt="" width={45} py-3 />
                                <h3 className="tracking-tight font-bold text-xl text-card-primary">Real Estate Aquisition in UtoposVerse</h3>
                                <p className='text-dark'>Purchasing virtual real estate within the UtoposVerse and earning revenue from the property.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-feature-promo h-full p-lg-4 p-4  mt-3 bg-white shadow-sm rounded-3xl border-1 border-[#9807cd]">
                            <div className="feature-info-wrap">
                                <img src="./assets/images/credit-card.png" alt="" width={45} py-3 />
                                <h3 className="tracking-tight font-bold text-xl text-card-primary">E-commerce and Trade</h3>
                                <p className='text-dark'>The Utopos Token (Utopos) serves as the currency for business-to-busines(B2B), Business-to-Consumer(B2C) trade with the blockchain city.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-feature-promo h-full p-lg-4 p-4  mt-3 bg-white shadow-sm rounded-3xl border-1 border-[#9807cd]">
                            <div className="feature-info-wrap">
                                <img src="./assets/images/credit-card.png" alt="" width={45} py-3 />
                                <h3 className="tracking-tight font-bold text-xl text-card-primary">Stake UTOP to earn rewards</h3>
                                <p className='text-dark'>Staking UTOP tokens to earn rewards and participate in governance decisions within the Utopos ecosystem.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3">
                        <div className="single-feature-promo h-full p-lg-4 p-4  mt-3 bg-white shadow-sm rounded-3xl border-1 border-[#9807cd]">
                            <div className="feature-info-wrap">
                                <img src="./assets/images/credit-card.png" alt="" width={45} py-3 />
                                <h3 className="tracking-tight font-bold text-xl text-card-primary">Payment and daily transactions</h3>
                                <p className='text-dark'>Using UTOP to pay for goods and services within the Utopos ecosystem, including in-game items, NFTs, and more.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3">
                        <div className="single-feature-promo h-full p-lg-4 p-4  mt-3 bg-white shadow-sm rounded-3xl border-1 border-[#9807cd]">
                            <div className="feature-info-wrap">
                                <img src="./assets/images/credit-card.png" alt="" width={45} py-3 />
                                <h3 className="tracking-tight font-bold text-xl text-card-primary">Trading</h3>
                                <p className='text-dark'>Trading UTOP on exchanges for other cryptocurrencies or fiat currencies.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Container>
        </section>
       
        <section className="why-choose-us ptb-20 bg-white">
            <div className="container">
                <div className="row justify-content-lg-between align-items-center ptb-60 md:space-x-10">
                
                    <div className="col-lg-6 col-12">
                        <div className="why-choose-content">
                           
                            <h2 className="tracking-tight font-bold text-5xl text-card-primary my-6">Connect, Play, Earn Utopos Tokens in UtoposVerse</h2>
                            <p className='text-dark text-xl mb-6'>Utopos is the first ever virtual universe where you can play games, relax and socialize with friends. subsiVerse is a social networking site dedicated solely to the community’s needs and interests. We are constantly working on making it better for you and your friends.</p>    
                            <a href="https://www.utoswap.com" rel='noreferrer' className="px-6 py-3 rounded-2xl text-white bg-spring font-bold uppercase font-arimo hover:bottom_banner" target="_blank">Buy Utopos Tokens</a>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                        <div className="feature-img-holder  mt-lg-0 mt-xl-0">
                            <img src={globe} className="img-fluid" alt="feature" width={500} />
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>

        
        <section className="section-invest-fundraising bg-slate-100 ptb-60" data-id="fundraising">
    <Container>
        <div className="row justify-content-center align-content-center">
            <div className="col-md-10 col-lg-8 py-6">
                <div className="section-heading text-center">
                    <h2 className="title-primary wow fade-in-up text-card-primary mb-2 tracking-tight">Utopos Token Distribution</h2>
                    <h4 className="title-basic wow fade-in-up text-dark text-sm">Token allocation</h4>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="wrapper">
                <div className="fundraising-row">
                    <div className="table-overview js-scroll-table wow fade-in-up"><span className="js-scroll-pointer scroll-pointer sm"><span className="scroll-text"></span></span>
                        <table className="table-data table-sale">
                            <thead>
                                <tr>
                                    <th>Purpose</th>
                                    <th>Token Allocation</th>
                                    <th>Token Amount (Utopos)</th>
                                    <th>Token Price($)</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Liquidity</td>
                                    <td>15%</td>
                                    <td>60,000,000</td>
                                    <td>- -</td>
                                    <td>Done</td>
                                </tr>
                                <tr>
                                    <td>Seed Sale</td>
                                        <td>2%</td>
                                        <td>1,000,000</td>
                                        <td>- -</td>
                                        <td>Done</td>
                                    </tr>
                                <tr>
                                     <td>Seed Sale</td>
                                     <td>2%</td>
                                     <td>5,000,000</td>
                                     <td>0.10</td>
                                     <td>20% unlocked at listing, 20% each</td>
                                  
                                </tr>
                                <tr>
                                    <td>Airdrop</td>
                                     <td>1%</td>
                                     <td>- -</td>
                                     <td>- -</td>
                                     <td>Done</td>
                                </tr>
                                <tr>
                                <td>Bounty</td>
                                     <td>1 %</td>
                                     <td>- -</td>
                                     <td>- -</td>
                                     <td>Done</td>
                                </tr>
                                <tr>
                                    <td>Pre-sale one</td>
                                    <td>5 %</td>
                                    <td>- -</td>
                                    <td>- -</td>
                                    <td>Upcoming</td>
                                </tr>
                                <tr>
                                    <td>Pre-sale two</td>
                                    <td>8 %</td>
                                    <td>- -</td>
                                    <td>- -</td>
                                    <td>Upcoming</td>
                                </tr>
                                <tr>
                                    <td>Pre-sale three</td>
                                    <td>3 %</td>
                                    <td>- -</td>
                                    <td>0.133</td>
                                    <td>20% unlocked at listing, 2 each month</td>
                                </tr>
                                <tr>
                                    <td>Crowdsale</td>
                                    <td>12 %</td>
                                    <td>- -</td>
                                    <td>- -</td>
                                    <td>Upcoming</td>
                                </tr>
                                <tr>
                                    <td>Reserved</td>
                                    <td>15 %</td>
                                    <td>- -</td>
                                    <td>- -</td>
                                    <td>Done</td>
                                </tr>
                                <tr>
                                    <td>Team/Founders</td>
                                    <td>20 %</td>
                                    <td>- -</td>
                                    <td>- -</td>
                                    <td>100% locked for 6months, 10% unlocked each month there after</td>
                                </tr>
                                <tr>
                                    <td>Marketing, Staking, Reward, Trading</td>
                                    <td>10 %</td>
                                    <td>- -</td>
                                    <td>- -</td>
                                    <td>60% unlocked, 10% Unlocked each month thereafter</td>
                                </tr>
                                <tr>
                                    <td>IDO</td>
                                    <td>2 %</td>
                                    <td>- -</td>
                                    <td>- -</td>
                                    <td>- -</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="fundraising-row usd">
                    <h3 className="title-basic wow fade-in-up text-center  py-6 text-white font-arimo font-bold">Project Timeline</h3>
                    <div className="table-overview wow fade-in-up">
                        <table className="table-data table-schedule">
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Activity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>April 2023</th>
                                    <td>Second phase of Utopos Pools live (extendedfunctionalities), Start development, Utopos Governance</td>
                                </tr>
                                <tr>
                                    <th>1st Release</th>
                                    <td>12 months vesting, fully unlock after vesting period expires and at the end of the 3rd release with same schedule</td>
                                </tr>
                                <tr>
                                    <th>Private Sale</th>
                                    <td>10 months vesting, 15% release at TGE (Token Generation Event), then unlock with a given rate in each month</td>
                                </tr>
                                <tr>
                                    <th>Pre - Sale</th>
                                    <td>9 months vesting, 15% release at TGE (Token Generation Event), then unlock with a given rate in each month</td>
                                </tr>
                                <tr>
                                    <th>Public Sale</th>
                                    <td>100% release at TGE (Token Generation Event)</td>
                                </tr>
                                <tr>
                                    <th>Developer</th>
                                    <td>3 years vesting, lock in first 2 years, unlock in 3rd year - equivalently in 12 months</td>
                                </tr>
                                <tr>
                                    <th>Technical</th>
                                    <td>18 months vesting, lock in first 6 months, then unlock equivalently in 12 months</td>
                                </tr>
                                <tr>
                                    <th>Advisor</th>
                                    <td>2 years vesting, lock in first 1 years, unlock in 2nd year - equivalently in 12 months</td>
                                </tr>
                                <tr>
                                    <th>Founders</th>
                                    <td>3 years vesting, equivalently in every month</td>
                                </tr>
                                <tr>
                                    <th>Marketing</th>
                                    <td>2 years vesting, lock in first 1 years, then unlock equivalently in 12 months</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </div>
        </div>
    </Container>
</section>
        
        <section className='bottom_banner  border-b-2 border-gray-700'>
            <Container>
                <div className="row py-3 align-items-center">
                    <div className="col-md-8">
                        <h1 className='text-black font-normal md:text-3xl sm:text-2xl mb-0'><span className='text-black'>2 Trillion</span> Utopos Token for Distribution</h1>
                        <p className='text-black'>Join global community of investors and owners transacting in Utopos today.</p>
                    </div>
                    <div className="col-md-4">
                        <div className="flex gap-2">
                            <a href="https://utoswap.com" className='text-white bg-card-primary py-2 px-3 bg-card-secondary rounded-md'>Buy Utopos Token</a>
                            <a href="https://polygonscan.com/token/0xa9f78ba8f650cd8cf6023bdbda978ee77cf739de" className='text-white border-card-primary border-2 py-2 px-3 rounded-md'>Smart Contract</a>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
   </>
  )
}