import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Container from '../Container'
import elon from '../../assets/images/utop_city.png'
import background from '../../assets/images/utop_bg.webp'
import agent_4 from '../../assets/images/agent_4.webp'
export default function HeroSection() {
  return (
   <>
    <div className="md:bg-forest/70 bg-card-primary">
    <div className="absolute z-[-1] w-full">
          <img src={background} alt="" className='utop_background'  />
      </div>
      <Container>
      <div className="row z-10 max-w-7xl align-items-middle  py-20 sm:py-48 lg:py-40 justify-between ">
         
          <div className="col-md-5" >
           
          </div>
          <div className="col-md-6 ">
            
            <div className="text-left pr-4">
              <h3 className='t1-grad text-xl font-bold font-arimo'>UTOP METAVERSE</h3>
              <h1 className="text-5xl  font-bold tracking-tight font-arimo text-white z-100">
              UtoposVerse -A Decentralized World of Wonders
              </h1>
              <p className="mt-6 text-md leading-8 text-white">
              UtoposVerse is a virtual reality game set in a real world that’s mapped to the game itself, which means you can teleport from an actual location to another and do real things like building real estate in Utopos cities and next to select landmarks across the world.        
              </p>
              <div className="mt-10 flex items-center justify-cd gap-x-6">
                <a
                  href="https://utoswap.com/"
                  className="rounded-xl jello-horizontal bg-spring px-8 py-2.5 text-md font-semibold leading-7 font-arimo text-white uppercase shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                 Join Our Waitlist
                </a>
                
              </div>
            </div>
          </div>
        
        </div>
      </Container>  
    </div>
   </>
  )
}