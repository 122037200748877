import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Content from "../components/Tokenomics/Content";

export default function Tokenomics() {
  return (
    <>
      <Header />
      <Content />
      <Footer />
    </>
  );
}