import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Header from "../../components/Layout/Header_02";
import Hero from "./HeroSection";
import Content from "./Content";
import background from '../../assets/images/image.png'
import SectionThree from "../../components/Home/SectionThree";
import Footer from "../../components/Layout/Footer";

class Tokens extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentWillMount = () => {
    console.log('Tokens will mount');
  }

  componentDidMount = () => {
    console.log('Tokens mounted');
  }

  componentWillReceiveProps = (nextProps) => {
    console.log('Tokens will receive props', nextProps);
  }

  componentWillUpdate = (nextProps, nextState) => {
    console.log('Tokens will update', nextProps, nextState);
  }

  componentDidUpdate = () => {
    console.log('Tokens did update');
  }

  componentWillUnmount = () => {
    console.log('Tokens will unmount');
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <>
      <div className="bg-card-primary md:bg-transparent">
        
      <div className="background-image">
      <Header />
        <Hero />
        <Content />
      </div>
      </div>
      <Footer />
    </>
    );
  }
}

Tokens.propTypes = {
  // bla: PropTypes.string,
};

Tokens.defaultProps = {
  // bla: 'test',
};

export default Tokens;
