import {useState, useEffect, Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import Logo from '../../assets/images/logo.png'
import {
  ArrowPathIcon,
  Bars3Icon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const solutions = [
  {
    name: 'Vehron DEX',
    description: 'A decentralized crypto exchange to help you exchange and trade crypto assets anywhere.',
    href: 'https://www.vehronswap.com',
    icon: ChartBarIcon,
  },
  {
    name: 'Vehron Wallet',
    description: 'A multichain wallet that helps you spend crypto and fiat in a simplified manner',
    href: '/wallet',
    icon: ShieldCheckIcon,
  },
  { name: 'Zionville Metaverse', 
    description: "A universe of business opportunities and creative autonomy.", 
    href: '/metaverse', 
    icon: Squares2X2Icon  
  },
  {
    name: 'Vehron Launchpad',
    description: "V-Pad will connect both crypto startups and investors in a cross-chain launchpad.",
    href: '/vehron-launchpad',
    icon: CursorArrowRaysIcon,
  },
  {
    name: 'VehronSwap',
    description: 'A Decentralized crypto exchange to swap, exchange and earn rewards in RIV',
    href: '#',
    icon: ArrowPathIcon,
  },
]
const callsToAction = [
  { name: 'Watch Demo', href: 'https://www.youtube.com/watch?v=KeZRrtMh6Tk', icon: PlayIcon },
  { name: 'Telegram', href: 'https://t.me/officialvehron', icon: PhoneIcon },
]
const resources = [
  {
    name: 'Exchange',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '/exchange',
    icon: LifebuoyIcon,
  },
  {
    name: 'Metaverse',
    description: 'Learn how to maximize our platform to get the most out of it.',
    href: '/metaverse',
    icon: BookmarkSquareIcon,
  },
  {
    name: 'NFT Marketplace',
    description: 'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: CalendarIcon,
  },
  { name: 'Tokenomics', description: 'Understand how we take your privacy seriously.', href: '/tokenomics', icon: ShieldCheckIcon },
]
const recentPosts = [
  { id: 1, name: 'Boost your conversion rate', href: '#' },
  { id: 2, name: 'How to use search engine optimization to drive traffic to your site', href: '#' },
  { id: 3, name: 'Improve your customer experience', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  return (
    <Popover className="relative bg-white z-10">
      <div className="mx-auto max-w-7xl px-6">
        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1 z-10">
            <a href="/">
              <span className="sr-only">Utopos</span>
              <img
                className="h-8 w-auto"
                src={Logo}
               
                alt=""
              />
            </a>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-purple-400 p-2 text-gray-50 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden  space-x-10 md:flex">
            <Popover className="relative">
              {({ open }) => (
                <>
                

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute  z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 bg-gray-900 px-5 py-6 sm:gap-8 sm:p-8">
                          {solutions.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 flex items-start rounded-lg p-2 hover:bg-gray-800"
                            >
                              <item.icon className="h-6 w-6 flex-shrink-0 text-indigo-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-300 mb-0">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className="space-y-6  px-5 py-3 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {callsToAction.map((item) => (
                            <div key={item.name} className="flow-root">
                              <a
                                href={item.href}
                                className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                              >
                                <item.icon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                <span className="ml-3">{item.name}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <a href="/" className="text-base font-medium text-card-primary hover:text-gray-900">
          About Utopos
            </a>
            <a href="/utopos-token" className="text-base font-medium text-card-primary hover:text-gray-900">
           Utopos Token
            </a>
            <a href="/metaverse" className="text-base font-medium text-card-primary hover:text-gray-900">
            Utoposverse
            </a>
            <a href="/utoswap" className="text-base font-medium text-card-primary hover:text-gray-900">
           Exchange
            </a>
            <a href="/tokenomics" className="text-base font-medium text-card-primary hover:text-gray-900">
           Tokenomics
            </a>
            <a href="/tokenomics" className="text-base font-medium text-card-primary hover:text-gray-900">
           NFT Market
            </a>
           
           
          </Popover.Group>
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            
            <a
              href="https://utoswap.com"
              className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md bg-winter px-4 py-2 text-base font-bold text-white shadow-sm hover:bg-purple-500 hover:text-white"
            >
              Buy UTOP Tokens
            </a>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute z-50 inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
          <div className="divide-y-2 divide-gray-900 rounded-lg bg-card-primary shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src={Logo}
                    alt="Subsi"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
               
              </div>
            </div>
            <div className="space-y-6 py-3 px-5 bg-white">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <a href="/" className="text-base font-medium text-card-primary hover:text-gray-700">
                
About Utopos
                </a>

                <a href="/utopos-token" className="text-base font-medium text-card-primary hover:text-gray-700">
                
Utopos Token
                </a>
                <a href="/humanitarian-hub" className="text-base font-medium text-card-primary hover:text-gray-700">
                
                Humanitarian Hub
                </a>
                {resources.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-base font-medium text-card-primary hover:text-gray-700"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div>
                <a
                  href="https://utoswap.com"
                  className="flex w-full items-center justify-center rounded-md bg-spring px-4 py-3 text-base font-bold text-forest shadow-sm hover:bg-black"
                >
              Buy on Utoswap
                </a>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Want to learn more?{' '}
                  <a href="https://t.me/utoposofficialgroup" className="text-indigo-600 hover:text-indigo-500">
                   Join on Telegram 
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>  
  )
}
