import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Container from '../Container'
import elon from '../../assets/images/utop_city.png'
import background from '../../assets/images/image.png'
import agent_4 from '../../assets/images/agent_4.webp'
export default function HeroSection() {
  return (
   <>
    <div className="md:bg-forest bg-winter">
   
      <Container>
      <div className="row z-10 max-w-7xl align-items-middle  py-20 sm:py-48 lg:py-40 justify-between ">
          <div className="col-md-6 ">
            
            <div className="text-left pr-4">
              <h3 className='t2-grad text-sm font-bold font-arimo'>UTOPOS CURRENCY</h3>
              <h1 className="text-5xl  font-bold tracking-tight font-arimo text-white z-100">
              A Crytpocurrency that powers the Utopos Blockchain City.
              </h1>
              <p className="mt-6 text-md leading-8 text-white">
              UTOP is the native cryptocurrency that powers the Utopos blockchain City. It is a digital token representing ownership and value of a community member's stakes in real estate and other types of assets created within Utopos.
              </p>
              <div className="mt-10 flex items-center justify-cd gap-x-6">
                <a
                  href="https://utoswap.com"
                  className="rounded-xl jello-horizontal uppercase bg-winter px-8 py-2.5 text-md font-bold leading-7 font-arimo text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
               Buy UTOP Token
                </a>
                
              </div>
            </div>
          </div>
          <div className="col-md-5" >
            <img src={agent_4} alt="feature" className="img-fluid rounded-custom " />
          </div>
          <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
            <svg
              className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
              viewBox="0 0 1155 678"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                fillOpacity=".3"
                d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
              />
              <defs>
                <linearGradient
                  id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9089FC" />
                  <stop offset={1} stopColor="#FF80B5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </Container>  
    </div>
   </>
  )
}