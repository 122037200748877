import React from 'react'
import { useState } from 'react';
import Container from '../Container';
import aitrader from '../../assets/images/business_man.png'
import aitrade from '../../assets/images/coins.webp'
import police from '../../assets/images/police.png'
import axios from 'axios';
import emailjs from 'emailjs-com';



function Content() {
        const [isSubmitted, setIsSubmitted] = useState(false);
        emailjs.init('YSQCHpZeTwlJ8ooaH');

       const handleSubmit = async (event) => {
            event.preventDefault();

            const { elements } = event.target;
            const data = {};

            // Convert form elements to a plain object
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                if (element.name) {
                data[element.name] = element.value;
                }
            }
            try {
                await emailjs.sendForm('service_ej9913j', 'template_t18pc6o', event.target);
                setIsSubmitted(true);
            } catch (error) {
                console.error(error);
                // Handle any error that occurred during form submission
            }
        };
      
  return (
    <>
    <section className='bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-forest via-forest to-card-primary'>
        <Container>
        <div className="row gap-4 pt-18  align-items-center justify-content-between">
            <div className="col-md-10 text-center mx-auto py-20">
            <h2 className="text-white font-bold tracking-tight font-arimo md:text-5xl text-4xl">Introducing the Utopian Entrepreneur Fund: Pioneering the Path to Utopos' Future</h2>
            </div>
            
            <div className="col-lg-6 col-md-12 z-10 mb-4">
                <div className="section-heading" data-aos="fade-up">
                    <p className="text-white">We are thrilled to unveil the groundbreaking initiative, the UTOPian Entrepreneur Fund, poised to revolutionize the way we approach innovation and development across various sectors. Utopos, the visionary blockchain-powered city of the future, has taken a momentous stride by launching this fund aimed at empowering entrepreneurs, architects, farmers, scientists, tech developers, and other professionals integral to the realization of Utopos' vision of it's blockchain smart city. The UTOPian Entrepreneur Fund stands as a testament to Utopos' commitment to nurturing groundbreaking ideas and fostering a thriving ecosystem that will shape the city's destiny.</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-7">
                    <div className="feature-img-wrap position-relative d-flex flex-column align-items-end">
                        <img src={aitrade} alt="feature" className="img-fluid rounded-custom" />
                    </div>
            </div>
        
        </div>
        <div className="row pt-24">
        <div className="col-lg-5 col-md-7">
                    <div className="feature-img-wrap position-relative d-flex flex-column align-items-end">
                        <img src={aitrader} alt="feature" className="img-fluid rounded-custom" />
                    </div>
            </div>
            <div className="col-md-6 ">
                <h2 className='text-white text-3xl font-bold py-4'>Designed to provide financial support and resources to individuals and teams across diverse industries.</h2>
                <p className='text-white'>The fund is a resounding call to action for those who dare to imagine and create. Entrepreneurs will find a nurturing environment to transform their innovative concepts into reality, architects will have the opportunity to reshape urban landscapes with sustainable and aesthetic designs, farmers will cultivate advanced agricultural practices that will sustain the cities, scientists will unlock breakthroughs that elevate human progress, and tech developers will weave the digital fabric that connects the city's smart infrastructure. This fund encapsulates the very essence of Utopos.</p>
                <p className='text-white'>The UTOPian Entrepreneur Fund beckons the dreamers, the creators, and the pioneers to step forward and be part of a journey that will shape the future of Utopos and, quite possibly, the world itself. As applications open, the call is clear: Utopos awaits those who seek to turn their aspirations into reality, and in doing so, contribute to the emergence of a city that stands as a testament to human potential.</p>
            </div>
            
        </div>
        </Container>
    </section>
    <section className='pt-24 bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-black via-card-primary to-black pb-24'>
        <Container>
            <div className="row ">
                <div className="col-md-6  text-white">
                    <h1 className='text-5xl font-bold text-white mb-4'>Utopos Homeless Veterans Helping Hand</h1>
                    <p>The Utopos team is proud to announce the launch of the first initiative undertaken by the Humanities Hub. A grant for the United States' homeless veterans. This initiative aligns with Utopos' value of giving back, specifically to men and women who have selflessly put their lives on the line for their country. Unfortunately, many brave veterans find themselves without proper care and support after their service.</p>
                    <p>Shockingly,over 38,000 military veterans are homeless , potentially grappling with PTSD, substance abuse, depression, and other service-related disabilities. These challenges not only make it harder for them to lead normal lives, but also hinder their ability to learn and participate in emerging technologies that are changing our lives every day. The Utopos Helping Hand plans to assist veterans not only with funds via airdrops of tokens, but also with free training on how to utilize emerging technologies like Web3, ensuring they are not left behind in the revolutionary advancements it brings.</p>
                </div>
                <div className="col-lg-5 md:offset-2">
                    
                <div className="feature-img-wrap position-relative d-flex flex-column align-items-end">
                        <img src={police} alt="feature" className="img-fluid rounded-custom mx-auto" />
                    </div>
                </div>
            </div>
        </Container>
    </section>
    <section className='pt-24 bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))] from-black via-card-primary to-black pb-24'>
        <Container>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="heading text-center pb-6">
                        <h2 className='font-extrabold tracking-tight text-5xl text-white'>Venture Application</h2>
                    </div>
                <div className="form-wrapper bg-card-secondary/10 border p-4 rounded-xl">
                {isSubmitted ? (
                        <p className='text-white text-center'>Thank you for your application, the form has been submitted successfully!. We will get back to you through your email with feedback.</p>
                    ) : (
                        <form onSubmit={handleSubmit} >
                        <div className="row form-group mb-3">
                          <div className="col-md-12">
                          <label htmlFor="project" className='text-white mb-2'>Project Name</label>
                            <input type="text" className='form-control' name='project' id='project' placeholder='What is your project name?' required/>
                          </div>
                        </div>
                        <div className="row form-group mb-3">
                          <div className="col-md-12">
                          <label htmlFor="project" className='text-white mb-2'>Cofounder Name</label>
                            <input type="text" className='form-control' name='cofounder' id='cofounder' placeholder='What is your cofounder name?' required/>
                          </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                            <label htmlFor="project" className='text-white mb-2'>Pitch Deck</label>
                            <input type="url" className='form-control' name='pitch' id='pitch_deck' placeholder='Link to your pitch on Google Drive/Dropbox/DocuSend' required />
                            </div>
                        </div>
                        <div className="row form-group my-3">
                            <div className="col-md-6">
                                <label htmlFor="email" className='text-white mb-2'>Email Address</label>
                                <input type="email" name='email' id='email' placeholder='johndoe@email.com' className='form-control'/>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="email" className='text-white mb-2'>LinkedIn Profile</label>
                                <input type="url" name='linkendin' id='linkendin' placeholder='Link to your Linkedin profile' className='form-control' required/>
                            </div>
                        </div>
                        <div className="row form-group my-3">
                            <div className="col-md-6">
                                <label htmlFor="telegram" className='text-white mb-2'>Telegram (optional)</label>
                                <input type="text" name='telegram' id='telegram' placeholder='Telegram handle' className='form-control'/>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="email" className='text-white mb-2'>Website Link</label>
                                <input type="url" name='website' id='website' placeholder='Starts with http:// or https://' className='form-control'/>
                            </div>
                        </div>
                        <div className="row form-group my-3">
                            <div className="col-md-12">
                                <label htmlFor="telegram" className='text-white mb-2'>Amount Requesting (in $UTOP or USD)</label>
                                <input type="text" name='amount' id='amount' placeholder='Explain how much you plan to raise in this current round, amount in $UTOP or USD' className='form-control'/>
                        </div>
                        </div>
                        <div className="row form-group mb-3 ">
                                <div className="col-md-12">
                                    <label htmlFor="project_desc" className='text-white mb-2'>Project description</label>
                                    <textarea  name="problem" id="problem" placeholder="Describe succinctly the problem you wish to solve and who you're trying to solve it for. " className='form-control' cols="300" rows="4"></textarea>
                                  
                                </div>
                            </div>
                            <div className="row form-group mb-3 ">
                                <div className="col-md-12">
                                    <label htmlFor="project_desc" className='text-white mb-2'>Why Utopos?(Optional)</label>
                                    <textarea  name="reason" id="problem" placeholder=" Why do you think Utopos is the right place for your project? " className='form-control' cols="300" rows="4"></textarea>
                                  
                                </div>
                            </div>
                            <div className="row form-group mb-3">
                                <div className="col-md-12">
                                        <label htmlFor="project_desc" className='text-white mb-2'>History(Optional)</label>
                                        <textarea  name="history" id="history" placeholder="How much money has been raised already for your project since it's inception?" className='form-control' cols="300" rows="4"></textarea>
                                        
                                    </div>
                            </div>
                            
                        <div className="row my-3">
                            <div className="col-md-12">
                                <button type='submit' className='text-card-primary rounded-md bg-white font-bold py-2 px-6'>Submit form</button>
                            </div>
                        </div>
                    </form>
                    )}
                    
                    </div>
                </div>
            </div>
        </Container>
    </section>
    </>
  )
}
export default Content;
