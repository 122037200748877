import React from 'react'
import HeroSection from '../components/Humanitarian/HeroSection'
import Header from '../components/Layout/Header';
import Content from '../components/Humanitarian/Content';
import Footer from '../components/Layout/Footer';


  function Humanitarian() {
  return (
        <>
            <Header />
            <HeroSection />
            <Content />
            <Footer />
        </>
  )
}
export default Humanitarian;
