import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Container from '../Container'
import elon from '../../assets/images/entre.webp'


export default function HeroSection() {
  return (
   <>
    <div className="bg-subsi_bg_2">
     <div className="" >
     <Container>
      <div className="row max-w-7xl align-items-middle  py-20 sm:py-48 lg:py-40 justify-between ">
          <div className="col-md-6 bg-white/80 rounded-2xl p-4">
            
            <div className="text-left">
              <h1 className="text-5xl pt-10 font-bold tracking-tight font-arimo text-card-primary animate__tracking-in-expand">
              Utopos Humanities Hub
              </h1>
              <p className="mt-6 text-md leading-2 text-dark">
              Welcome to the Utopos Humanities Hub! This department is responsible for managing all Utopos approved humanitarian and charitable efforts from within the ecosystem that matches our values of altruism, helpfulness, giving back, providing equity and fairness. Below is a list of ongoing and past efforts. For information on how you can help out.
              </p>
              <div className="mt-10 flex items-center justify-cd gap-x-6">
                <a
                  href="https://utoswap.com/"
                  className="rounded-xl jello-horizontal bg-spring px-8 py-2.5 text-md font-semibold leading-7 font-arimo text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Buy Utop Tokens
                </a>
                <a href="https://t.me/utoposofficialgroup" className="text-base font-semibold leading-7 text-card-primary">
                  Join Telegram Community <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6" >
            
          </div>
          
          
        </div>
      </Container>
    </div>
    </div>
    
   </>
  )
}