import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Container from '../Container'
import elon from '../../assets/images/utop_city.png'


export default function HeroSection() {
  return (
   <>
    <div className="bg-subsi_bg">
     <div className="" >
     <Container>
      <div className="row max-w-7xl align-items-middle  py-20 sm:py-48 lg:py-40 justify-between ">
          <div className="col-md-6 bg-white/80 rounded-2xl p-4">
            
            <div className="text-left">
              <h1 className="text-3xl font-bold tracking-tight font-arimo text-card-primary animate__tracking-in-expand">
              Utopos City: Pioneering the Future of Urban IoT with AI, Blockchain, VR, and Robotics.
              </h1>
              <p className="mt-6 text-md leading-2 text-dark">
              Utopos is a project aimed at building futuristic cities powered by blockchain, AI, IoT, and VR technologies on a global scale. As the first real "digital dirt" city, Blockchain City is being designed and built from the ground up, incorporating best practices for disruptive technologies. $UTOP will serve as the native token for everyday transactions within the city.
              </p>
              <div className="mt-10 flex items-center justify-cd gap-x-6">
                <a
                  href="https://utoswap.com/"
                  className="rounded-xl jello-horizontal bg-spring px-8 py-2.5 text-md font-semibold leading-7 font-arimo text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Buy Utop Tokens
                </a>
                <a href="https://t.me/utoposofficialgroup" className="text-base font-semibold leading-7 text-spring">
                  Join Telegram Community <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6" >
            
          </div>
          
          <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
            <svg
              className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
              viewBox="0 0 1155 678"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                fillOpacity=".3"
                d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
              />
              <defs>
                <linearGradient
                  id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9089FC" />
                  <stop offset={1} stopColor="#FF80B5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </Container>
    </div>
    </div>
     <div className="wavebg absolute top-[800px] opacity-30">
     <img src="./../../assets/img/wave.png" alt="subsi"  />
 </div>
   </>
  )
}