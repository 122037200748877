import Header from "../components/Layout//Header_02";
import Hero from "../components/metaverse/HeroSection";
import Content from "../components/metaverse/Content";
import SectionThree from "../components/Home/SectionThree";
import Footer from "../components/Layout/Footer";

export default function Home() {
  return (
    <>
      <Header />
      <Hero />
      <Content />
      <Footer />
    </>
  );
}
