import React from "react"
import Container from "../Container"
import row from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "bootstrap/dist/css/bootstrap.min.css";
import { faPieChart, faUsers, faMobile } from '@fortawesome/free-solid-svg-icons'
import aitrader from '../../assets/images/triangle.png'



export default function Content(props){
    
    return (
        <>
        <section className="promo-section ptb-120 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-spring via-forest  to-card-primary   feature-section  ptb-120">
        <Container>           
        <div className="row align-middle">
        <div className="col-md-6 col-lg-5 pb-4">
                  <div className="mx-auto  items-center justify-center align-middle">
                        <img src="../../../assets/img/swap_01.webp" alt="UtoSwap" className="text-center"/>
                      </div>
                  </div>
                <div className="col-md-6 col-lg-7 pb-4">
               <div className="bg-[#000000] p-4">
               <h2 className="font-bold text-2xl leading-6 md:text-4xl tracking-tight font-arimo text-white t1-grad">Buy, Sell, Earn, and Trade, 200+ top cryptocurrencies with the world's easiest-to-use DEX.</h2>
                        <p class="text-white">UtoSwap is a cryptocurrency exchange that makes it easy to buy, sell, earn and
trade 150+ cryptocurrencies. With a simple but powerful user interface and features such as
&#39;instant login&#39;, you can access the wealth of potential that blockchain technology promises.
UtoSwap puts the power of exchange in the hands of everyone.</p>
                   
               </div>
               <div className="depth-cliff"></div>
                </div>
                
            </div>
           
        </Container>
        </section>
        <section className="feature-section-two ptb-120 bg-black">
        <Container>
        
        <div className="row  align-items-center justify-content-between">
        <div className="col-lg-7 col-md-12 z-10">
                <div className="section-headingd rounded-[60px] border-v bg-card-secondary/20 p-4 md:px-4 " data-aos="fade-up">
                    <h2 className="text-white font-bold tracking-tight font-arimo ">We are building the most friendly DEX exchange with UtoSwap</h2>
                    <p className="text-white pt-4">UtoSwap is a DEX exchange for Utopos token holders. It will also support cross-chain trading with other blockchains such as ETH, BTC, EOS and etc. We aim to build the most friendly and secure exchange with lowest fees that can be used by regular people.</p>
                   
                </div>
            </div>
        <div className="col-lg-5 col-md-7">
                <div className="feature-img-wrap position-relative d-flex flex-column align-items-end">
                    
                    <img src={aitrader} alt="feature" className="img-fluid rounded-custom" />
                </div>
            </div>
           
           
        </div>
        <div className="row py-5">
            <div className="col-md-10 text-center mx-auto">
              <div className="text-center bg-[#24fbb4]  p-3 rounded-xl py-6">
              <h3 className="text-black ">Join our community and be amongst the first to start trading on a well filtered pool of digital with 
assets listed on UtoSwap</h3>
                <div className="pt-4 mb-4">
                    <a href="https://t.me/utoposofficialgroup" className="py-3 px-4 bg-black font-bold text-white font-arimo rounded-2xl uppercase text-[16px] hover:bg-black/40 ">Join Our Community</a>
                </div>
              </div>
              <div className="depth-cliff"></div>
            </div>
        </div>
        </Container>
        </section>
     
        <section className="work-process bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#0a4b19] via-black to-black ptb-120 ">
   <Container>
  
        <div className="row align-items-center justify-content-between">
          
             <div className="col-lg-8 col-md-12 order-1 order-lg-1">
                <div className="row">
                    <div className="md:col-md-6 col-6 ">
                        <div className="subsi_card rounded-3xl bg-[#1f1e1e]/40 border-v py-3 px-4 mb-2 text-white shadow-md">
                            <h4 className="font-arimo text-lg">Manage your portfolio</h4>
                            <p>Buy and sell popular digital currencies, such as Bitcoin and Ethereum in one place.</p>
                        </div>
                    </div>
                    <div className="sm:col-md-6 col-6">
                        <div className="subsi_card rounded-3xl bg-[#1f1e1e]/40 border-v py-3 px-4  mb-2  text-white shadow-md">
                            <h4 className="font-arimo text-lg">Recurring buys</h4>
                            <p>Invest in cryptocurrencies at your own pace. You can schedule buys daily, weekly, monthly or
customized to fit your cryptocurrency investment strategy.</p>
                        </div>
                    </div>
                    <div className="sm:col-md-6 col-6">
                        <div className="subsi_card rounded-3xl bg-[#1f1e1e]/40 border-v py-3 px-4  mb-2 text-white shadow-md">
                            <h4 className="font-arimo text-lg">Personalised Access Control</h4>
                            <p>For added security, we provide you with the option to restrict access to your account by devices
and/or addresses</p>
                        </div>
                    </div>
                    <div className="sm:col-md-6 col-6">
                        <div className="subsi_card rounded-3xl bg-[#1f1e1e]/40 border-v py-3 px-4 mb-2 text-white shadow-md">
                            <h4 className="font-arimo text-lg">Advance Data Encryption</h4>
                            <p>For added security, we provide you with the option to restrict access to your account by devices
and/or addresses</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 order-0 order-lg-0">
               <h2 className="text-spring font-bold tracking-tight text-5xl font-arimo jello-horizontal">Create your own cryptocurrency portfolio today</h2>
               <p className="text-white">UtoSwap helps you create your own cryptocurrency portfolio today. It lets you diversify across cryptocurrencies, and allows you to pick your favorite ones for investment.</p>
<div className="mt-10 flex items-center justify-cd gap-x-6">
                <a
                  href="https://UtoSwap.com"
                  className="rounded-xl jello-horizontal bg-spring px-8 py-2.5 text-md font-bold leading-7 font-arimo text-forest uppercase shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                Try UtoSwap Beta
                </a>
                
              </div>
            </div>
        </div>
   </Container>
</section>
<section className="feature-section-two  special-bg">
   <div className="bg-[#18a84585] ptb-120">
   <Container>
       <div className="row">
        <div className="col-12">
            <div className="text-center text-white pb-6">
                <h3 className="font-arimo t1-grad">Platform Features</h3>
                <h2 className="text-3xl md:text-5xl font-arimo tracking-tight">A Decentralized Trading Platform</h2>
            </div>
        </div>
        <div className="col-md-6">
                <div className="bg-card-primary/40 border-v rounded-2xl shadow-md p-4 mb-2">
                    <h3 className="text-white font-arimo text-xl">Decentralized Exchange</h3>
                    <p className="text-gray-300">UtoSwap is a decentralized exchanger network that allows its users to exchange fast, safe, and reliable cryptocurrency on-chain and token-to-token in a fully decentralized way.</p>
                </div>
            </div>
            <div className="col-md-6">
                <div className="bg-card-primary/40 border-v rounded-2xl shadow-md p-4 mb-2">
                    <h3 className="text-white font-arimo text-xl">Supply Liquidity</h3>
                    <p className="text-gray-300">Liquidity providers are key to the success of an exchange. They provide funding to traders
wanting to trade with leverage, and in exchange they earn a portion of the daily interest
payments and rebate on fees.</p>
                </div>
            </div>
            <div className="col-md-6">
                <div className="bg-card-primary/40 border-v rounded-2xl shadow-md p-4 mb-2">
                    <h3 className="text-white font-arimo text-xl">Instant Withdrawals</h3>
                    <p className="text-gray-300">As a trader who utilizes the Utopos DEX, you may withdraw your funds at any time. We utilize
advanced technology to ensure instant and safe payout to our traders. The Utopos DEX
platform is designed to assist traders who want to withdraw funds from their trading accounts.</p>
                </div>
            </div>
            <div className="col-md-6">
                <div className="bg-card-primary/40 border-v rounded-2xl shadow-md p-4 mb-2">
                    <h3 className="text-white font-arimo text-xl">Trade from anywhere</h3>
                    <p className="text-gray-300 text-xl">We are preparing a set of necessary tools in our secure app and wallet with a clear interface</p>
                </div>
            </div>
       </div>
      
    </Container>
    </div>    
</section>
<section className="feature-section-two bg-[radial-gradient(ellipse_at_left,_var(--tw-gradient-stops))] from-[#246b03] via-black to-black ptb-120">
    <Container>
            <div className="row">
                <div className="col-md-6">
                    <img src="../assets/img/gem.webp" alt="Utopos token"  />
                </div>
                <div className="col-md-6">
                    <h4 className="t2-grad">Use Utopos</h4>
                    <h2 className="font-arimo text-5xl text-white">Earn Utopos Tokens by Contributing to a Substainable Green Future</h2>
                    <p className="text-gray-300 my-4">Utopos is a blockchain-based carbon credit token that drives down emission and promotes sustainability and green living through reward mechanisms.</p>
<div className="mt-4 flex gap-4">
    <a href="https://UtoSwap.com" className="rounded-2xl py-3 px-4 bg-spring font-arimo text-forest font-bold hover:bg-green-800">Buy Utopos Tokens</a>
    <a href="/Utopos-token" className="font-bold text-white py-3 px-4 border-2 rounded-2xl">More about Utopos</a>
</div>

                </div>
            </div>
    </Container>
</section>
<section className="feature-section-two bg-[radial-gradient(ellipse_at_right,_var(--tw-gradient-stops))] from-green-900 via-forest to-green-800 ptb-120">
    <Container>
            <div className="row justify-items-between">
                
                <div className="col-md-6">
                    <h4 className="text-spring">Crypto Lending</h4>
                    <h2 className="font-arimo text-5xl text-white">Crypto Lending</h2>
                    <p className="text-gray-300 my-4">Utopos Crypto Lending offers you a unique opportunity to make money on your crypto assets!
You can safely lend them through our service, with no risks. Lend different coins or tokens and
earn profit with daily payments. In addition, Utopos Crypto Lending also offers an additional
program for active investors with rate higher than the average interests rate in the market.</p>
<div className="mt-4 flex gap-4">
    <a href="https://UtoSwap.com" className="rounded-2xl py-3 px-4 bg-spring font-arimo text-forest font-bold hover:bg-green-800">Buy UTOP Tokens</a>
</div>
                </div>
                <div className="col-md-6">
                    <img src="../assets/img/give.png" alt="Utopos token" width={350} height={350} />
                </div>
            </div>
    </Container>
</section>
        </>
    )
}