import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
import Home from "./pages/Home";
import Metaverse from "./pages/Metaverse";
import Tokens from "./components/Tokens";
import Exchange from "./pages/Exchange";
import Tokenomics from "./pages/Tokenomics";
import Humanitarian from "./pages/Humanitarian";
const queryClient = new QueryClient();
library.add(fab, faCheckSquare, faCoffee);
function App() {
  
  return (
    <Web3ReactProvider getLibrary='#'>
      <QueryClientProvider client={queryClient}>
        <div className="w-screen min-h-screen">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/metaverse" element={<Metaverse />} />
              <Route path="/utopos-token" element={<Tokens />} />
              <Route path="/utoswap" element={<Exchange />} />
              <Route path="/tokenomics" element={<Tokenomics />} />
              <Route path="/humanitarian-hub" element={<Humanitarian />} />
            </Routes>
          </BrowserRouter>
        </div>
        <Toaster position="top-right" />
      </QueryClientProvider>
    </Web3ReactProvider>
  );
}

export default App;
