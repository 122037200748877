import React from "react"
import Container from "../Container"
import row from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "bootstrap/dist/css/bootstrap.min.css";
import { faPieChart, faUsers, faMobile } from '@fortawesome/free-solid-svg-icons'
import ai from '../../assets/images/agent_3.webp'
import agent_3 from '../../assets/images/agent_5.webp'
import leftdeco from '../../assets/images/subsi-ring.webp'
import aitrader from '../../assets/images/triangle.png'
import utop_user from '../../assets/images/subsi_user.png'

const features = [
  {
    name: 'International free trade zone',
    description: 'The City is planning to apply for several preferential designations including status as an international free tradezone. We are also seeking certifications regarding best practices for sustainable communities.',
    href: 'https://www.d.com',

  },
  {
    name: 'subsi Metaverse',
    description: 'subsi metaverse allow users to explore the city virtually, buy and sell land and operate business in a digital space through the "Dirt" layer that is powered by the ERTH Token.',
    href: '/wallet',

  },
  {
    name: 'Virtual Reality',
    description: "Virtual Reality technology in allows you to inspect properties under constructions, explore land and real-estate properties with high-res VR visuals, inspections are easy.",
    href: '/metaverse',
  },
  {
    name: 'Virtual Tourism',
    description: "Virtual tourism in subsi City allow everyone in the real-world to explore important touristic sites in the virtual city through high resolution Virtual reality glasses",
    href: '/vehron-launchpad',

  },
  {
    name: 'V-Gigs & V-Lancing',
    description: 'From an impresive 3D teleconferencing capability, to the world\'s most advanced VR platform for freelancers and gigworkers across all technical and creative fields, subsi City makes life easy.',
    href: '#',

  },
  {
    name: 'Virtual Gaming',
    description: 'From GTA-style vehicle chases and shootouts to treasure hunts and other crowd events, subsi city will be the  world’s premium site for hyper-realistic v-gaming',
    href: '#',

  },
  {
    name: 'The Digital Wild West',
    description: 'With blockchain-backed security and privacy, Blockchain City offers an ultimate 3D virtual relationship and dating community.',
    href: '#',

  },

  {
    name: 'IoT & IoB Support ',
    description: 'Fueled by AI blockchain, VR, and robotic technologies, the City will be attestbed for developing a comprehensive Internet of Things to cover an entire urban area',
    href: '#',

  },

]
export default function Content(props) {

  return (
    <>
      <section className="promo-section ptb-120 bg-white feature-section  ptb-120">
        <Container>

          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-8 pb-4">
              <div className="section-headings text-center" data-aos="fade-up">
                <h2 className="font-bold tracking-tight font-arimo text-card-primary text-4xl md:text-4xl">$UTOP Tokens Control the Commercial layer of Blockchain City.</h2>
                <p className="text-dark">UTOP is a non-asset-backed token compliant with the MRC-20 standard. The UTOP token is a collectible novelty, and it's used through out the City's ecosystem for buying and selling, e-commerce, payments, and all daily financial transactions.</p>
              </div>
            </div>
          </div>
          <div className="row pt-6">
            <div className="col-md-6 col-lg-3 mt-4 mt-lg-0 mt-md-0">
              <div className="bg-white border-v hover:bg-green-500 p-3 text-center d-flex  flex-column h-100 rounded-custom" data-aos="fade-up" data-aos-delay="100">
                <div className="promo-card-info mb-4">
                  <h3 className=" text-white  py-1 text-lg font-bold px-4 rounded-full bg-winter">Network Type</h3>
                  <h3 className="display-5 fw-bold mb-4 text-card-primary hover:text-slate-800 pt-3"> Polygon
                  </h3>
                  <p className="text-dark mb-3">Non-fungible, collectible novelty tokens controlling each square meter of the City.</p>
                  <div className="mt-3">
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-6 col-lg-3 mt-4 mt-lg-0 mt-md-0">
              <div className="bg-white hover:bg-card border-v p-3 text-center d-flex flex-column h-100 rounded-custom  border-spacing-1 border-card" data-aos="fade-up" data-aos-delay="150">
                <div className="promo-card-info mb-4">
                  <h3 className=" text-white py-1 text-lg font-bold px-4 rounded-full bg-card">Token Type</h3>
                  <h3 className="display-5 fw-extrabold mb-4 text-card pt-3">MRC-20
                  </h3>
                  <p className="text-dark">Fungible,collectible novelty tokens for transactions within the City and worldwide</p>
                  <div className="mt-3">

                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-6 col-lg-3 mt-4 mt-lg-0">
              <div className="bg-white p-3 border-v hover:bg-purple-600 text-center d-flex flex-column h-100 rounded-custom" data-aos="fade-up" data-aos-delay="200">
                <div className="promo-card-info mb-4">
                  <h3 className=" text-white py-1 text-lg font-bold px-4 rounded-full bg-purple-600">Total Supply</h3>
                  <h3 className="display-5 fw-bold mb-4 text-card-primary">
                    2 Trillion</h3>
                  <p className="text-dark">Administrative tokens for governing smart contracts and other digital interactions between and among Earth and Utopos token owners.
                  </p>
                  <div className="mt-3">
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-6 col-lg-3 mt-4 mt-lg-0">
              <div className="bg-spring p-3 border-v hover:bg-purple-600 text-center d-flex flex-column h-100 rounded-custom" data-aos="fade-up" data-aos-delay="200">
                <div className="promo-card-info mb-4">
                  <h3 className=" text-white py-1 text-lg font-bold px-4 rounded-full bg-spring">Token Ticker</h3>
                  <h3 className="display-5 fw-bold mb-4 text-white">
                    UTOP</h3>
                  <p className="text-white">The UTOP token serves as the primary currency within the City's ecosystem, used for buying and selling, e-commerce, payments, and all daily financial transactions.
                  </p>
                  <div className="mt-3">
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="feature-section-two bg-card-primary ptb-120 ">
        <Container>

          <div className="row  align-items-center justify-content-between">
            <div className="col-lg-5 col-md-7">
              <div className="feature-img-wrap position-relative d-flex flex-column align-items-end">

                <img src={aitrader} alt="feature" className="img-fluid rounded-custom" />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 z-10">
              <div className="section-heading rounded-[60px] border-v bg-card-secondary/20 p-4" data-aos="fade-up">
                <h2 className="text-white text-5xl font-bold tracking-tight font-arimo">UTOP is considered as the new “hard times token”</h2>

                <p className="text-white">$UTOP is considered the new 'hard times token' due to its ability to provide trust, transparency, and opportunities for value growth within the Utopos blockchain city. For competitive minting purposes, the UTOP token is designed to pseudonymously track the performance of businesses that use them, even if those businesses are using other blockchains instead of Polygon.
                </p>

              </div>
            </div>

          </div>
        </Container>
      </section>

      
      <section className="work-process bg-white ptb-120 ">
        <div className="max-w-7xl px-4 w-full  xl:px-9 lg:mx-auto undefined">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 col-md-12 order-0 order-lg-1">

              <h2 className="text-card-primary font-bold tracking-tight font-arimo jello-horizontal text-4xl md:text-5xl">Connect, Play, Earn and Relax in UtoposVerse</h2>
              <p className="text-dark my-4">Welcome to Utopos, the ultimate destination for a futuristic and exciting lifestyle! Our blockchain city offers a unique blend of ecommerce, gaming, dating, innovation, social networking, leisure, and education - all in one place.</p>
              <div className="mt-10 flex items-center justify-cd gap-x-6">
                <a href="/metaverse" className="rounded-xl jello-horizontal Utopos_btn px-8 py-2.5 text-md font-semibold leading-7 font-arimo text-card uppercase shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Learn about UtoposVerse</a>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 order-1 order-lg-0">
              <div className="img-wrap" data-aos="fade-up" data-aos-delay="50">
                <img src="/static/media/agent_5.a192cefc78ea5db45b5a.webp" alt="work process" className="img-fluid rounded-custom" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}