import React from "react";
import Style from "../../styles/main.css";
import Container from "../Container";


const logos = [
    {
        src: './../../assets/img/solana.svg',
        alt: 'Solana'
    },
    {
        src: './../../assets/img/coin_gecko.svg',
        alt: 'Vanguardaudit'
    },
    {
        src: './../../assets/img/coin_partner.svg',
        alt: 'Coin'
    },
    {
        src: './../../assets/img/cryptorank_partner.svg',
        alt: 'Vanguardaudit'
    },
    {
        src: './../../assets/img/kucoin.webp',
        alt: 'Kucoin'
    },
    {
        src: './../../assets/img/polygon.png',
        alt: 'Polygon'
    },
    {
        src: './../../assets/img/crypto_news.png',
        alt: 'Vanguardaudit'
    },
    {
        src: './../../assets/img/binance-logo.png',
        alt: 'Binance Chain'
    },
    {
        src: './../../assets/img/uniswap.png',
        alt: 'Etherium'
    },
   
]

export default function PromoSection(props) {
    return (
        <>
       
        <div className="customer-section bg-spring pt-2">
           <Container>
                <div className="row justify-content-center">
                    <div className="lg:col-md-8 sm:col-12 justify-center mx-auto">
                    <div class="parent">
                    {logos.map((item) => (
                        <div class="div1"> 
                        <img
                              src={item.src}
                              alt={item.alt}
                              className="img-fluid p-1 p-md-2 p-lg-3 m-auto "
                              width={150}
                            />
                        </div>
                         ))}  
                        </div>
                        
                    </div>
                </div>
                </Container>
        </div>
        </>
    );
}