import React from "react"
import Container from "../Container"
import row from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "bootstrap/dist/css/bootstrap.min.css";
import { faPieChart, faUsers, faMobile } from '@fortawesome/free-solid-svg-icons'
import ai from '../../assets/images/agent_3.webp'
import agent_3 from '../../assets/images/agent_5.webp'
import leftdeco from '../../assets/images/subsi-ring.webp'
import aitrader from '../../assets/images/vr_metaverse.webp'
import iot from '../../assets/images/iot.webp'
import econ from '../../assets/images/econ.webp'
import utop_user from '../../assets/images/subsi_user.png'

const features = [
    {
        name: 'International free trade zone',
        description: 'The City is planning to apply for several preferential designations including status as an international free tradezone. We are also seeking certifications regarding best practices for sustainable communities.',
        href: 'https://www.d.com',

    },
    {
        name: 'Subsi Metaverse',
        description: 'Subsi metaverse allow users to explore the city virtually, buy and sell land and operate business in a digital space through the "Dirt" layer that is powered by the ERTH Token.',
        href: '/wallet',

    },
    {
        name: 'Virtual Reality',
        description: "Virtual Reality technology in allows you to inspect properties under constructions, explore land and real-estate properties with high-res VR visuals, inspections are easy.",
        href: '/metaverse',
    },
    {
        name: 'Virtual Tourism',
        description: "Virtual tourism in subsi City allow everyone in the real-world to explore important touristic sites in the virtual city through high resolution Virtual reality glasses",
        href: '/vehron-launchpad',

    },
    {
        name: 'V-Gigs & V-Lancing',
        description: 'From an impresive 3D teleconferencing capability, to the world\'s most advanced VR platform for freelancers and gigworkers across all technical and creative fields, subsi City makes life easy.',
        href: '#',

    },
    {
        name: 'Virtual Gaming',
        description: 'From GTA-style vehicle chases and shootouts to treasure hunts and other crowd events, subsi city will be the  world’s premium site for hyper-realistic v-gaming',
        href: '#',

    },
    {
        name: 'The Digital Wild West',
        description: 'With blockchain-backed security and privacy, Blockchain City offers an ultimate 3D virtual relationship and dating community.',
        href: '#',

    },

    {
        name: 'IoT & IoB Support ',
        description: 'Fueled by AI blockchain, VR, and robotic technologies, the City will be attestbed for developing a comprehensive Internet of Things to cover an entire urban area',
        href: '#',

    },

]
export default function Content(props) {

    return (
        <>
            <section className="promo-section ptb-120  feature-section bg-[conic-gradient(at_right,_var(--tw-gradient-stops))] from-white via-spring/10 to-white ptb-120">
                <Container>

                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9 pb-4">
                            <div className="section-headings text-center" data-aos="fade-up">
                                <h2 className="font-bold tracking-tight text-4xl md:text-5xl font-arimo text-card-primary">Join the world of imagination and innovation, earn $UTOP</h2>
                                <p className="text-dark">Welcome to UtoposVerse, the world’s first play to earn and property strategy with real world ownerships in assets and real estate. Your adventure in the Metaverse starts here. Enjoy a virtual reality experience of the future and explore new adventures as this metaverse is mapped to the real world, education, medicine, social networks, etc. Real-time experiences and interactions with your friends, daily game updates and region events are the features that set this Metaverse apart from all other virtual reality games. Earn Utopos UTOP by engaging in a global competition for building the largest empire in our world!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-6">
                        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
                            <div className="bg-white hover:bg-green-500 p-5 text-center d-flex  flex-column h-100 rounded-custom" data-aos="fade-up" data-aos-delay="100">
                                <div className="promo-card-info mb-4">
                                    <h3 className=" text-slate-800 absolute mt-[-65px]  py-1 text-lg font-bold px-4 rounded-full bg-green-400">Design and Build on Utopos City</h3>
                                    <h3 className="display-5 fw-bold mb-4 text-card-primary hover:text-slate-800 "> Explore
                                    </h3>
                                    <p className="text-dark mb-3">Design, build and rent real estate infrastructures in an eco-friendly futuristic virtual environment</p>
                                    <div className="mt-3">
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
                            <div className="bg-white hover:bg-card p-5 text-center d-flex flex-column h-100 rounded-custom  border-spacing-1 border-card" data-aos="fade-up" data-aos-delay="150">
                                <div className="promo-card-info mb-4">
                                    <h3 className=" text-white absolute mt-[-65px] ml-5 py-1 text-lg font-bold px-4 rounded-full bg-card">Play and Earn UTOP Tokens</h3>
                                    <h3 className="display-5 fw-extrabold mb-4 text-card-primary">Play & Earn
                                    </h3>
                                    <p className="text-dark">Earn UTOP tokens when you participate in activities in the UtoposVerse.</p>
                                    <div className="mt-3">

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0">
                            <div className="bg-white p-5 hover:bg-green-800 text-center d-flex flex-column h-100 rounded-custom" data-aos="fade-up" data-aos-delay="200">
                                <div className="promo-card-info mb-4">
                                    <h3 className=" text-card-primary absolute mt-[-65px] ml-5 py-1 text-lg font-bold px-4 rounded-full bg-[#52c41a]"> Utopos NFT Marketplace</h3>
                                    <h3 className="display-5 fw-bold mb-4 text-card-primary">
                                        NFT</h3>
                                    <p className="text-dark">The Utopos NFT marketplace serves as a great place to buy, sell and exchange NFTs in UtoposVerse.</p>
                                    <div className="mt-3">
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </Container>
            </section>
            <section className="feature-section-two ptb-120 bg-forest">
                <Container>

                    <div className="row  align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-7">
                            <div className="feature-img-wrap position-relative d-flex flex-column align-items-end">

                                <img src={aitrader} alt="feature" className="img-fluid rounded-custom" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 z-10">
                            <div className="section-heading rounded-[60px] border-v bg-card-secondary/20 p-4" data-aos="fade-up">
                                <h2 className="text-white font-bold tracking-tight font-arimo text-5xl">Enjoy a virtual reality experience of the future. </h2>
                                <p className="text-white">Explore new adventures as this metaverse is mapped to the real world, education, medicine, social networks, etc. Real-time experiences and interactions with your friends, daily game updates and region events are the features that set this Metaverse apart from all other virtual reality games. Earn Utopos UTOP by engaging in a global competition for building largest empire in our world!</p>

                            </div>
                        </div>

                    </div>
                </Container>
            </section>
            <section className="feature-section bg-[radial-gradient(ellipse_at_top_center,_var(--tw-gradient-stops))] from-white via-white to-[#e9e3ff] ptb-120">
                <div className="max-w-7xl px-4 w-full  xl:px-9 lg:mx-auto undefined">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6 col-md-6">
                            <div className="feature-content-wrap" data-aos="fade-up" data-aos-delay="50">
                                <h2 className="text-capitalize font-bold font-arimo text-card-primary tracking-tight text-4xl md:text-5xl">A Decentralized Metaverse</h2>
                                <p className="text-dark">Utopos is a decentralized metaverse that offers users a safe and fast way to own their digital content while also enabling interaction with other users. Join our community and experience a new level of freedom in the virtual world!</p>
                            </div>
                            <div className="layers">
                                <div className="border-v bg-white shadow-md p-3 rounded-[20px] mb-2">
                                    <h3 className="font-medium text-[20px] text-card-primary ">Built on Polygon (Matic)</h3>
                                    <p className="text-sm text-dark">UtoposVerse will provide an unprecedented VR experience for the crypto community using AI and advanced technologies, bringing users together in one immersive and engaging environment.</p>
                                </div>
                                <div className="border-v bg-white shadow-md p-3 rounded-[20px] mb-2">
                                    <h3 className="font-medium text-[20px] text-card-primary ">Build and Play</h3>
                                    <p className="text-sm text-dark">Experience the ultimate in virtual property ownership with UtoposVerse Platform. You can buy your very first virtual property, build on it, and even sell it to other users in the metaverse. Plus, with the ability to purchase real-world locations in Utopos city, the possibilities are endless. Collaborate with other like-minded Utopians to develop neighborhoods and build entire worlds for you and your friends to explore and enjoy. Let your imagination run wild and create a virtual empire of your own.
                                    </p>
                                </div>
                                <div className="border-v bg-white shadow-md p-3 rounded-[20px] mb-2">
                                    <h3 className="font-medium text-[20px] text-card-primary ">Earn UTOP Tokens</h3>
                                    <p className="text-sm text-dark">Earn UTOP Tokens in UtoposVerse, the new virtual economy where you can generate revenue through multiple income streams. Start by earning a return on your properties and collecting fees from visitors, then scale up to create classrooms, host live shows, and schedule meetings with business owners nearby. The metaverse has never been so profitable! Join UtoposVerse and become a part of the exciting world of virtual real estate and digital entrepreneurship.</p></div></div></div><div className="col-lg-6 col-md-6"><div className="image-wrap mb-5 mb-md-0 mb-lg-0 mb-xl-0  p-2 rounded-lg" data-aos="fade-up">
                                        <img src="/static/media/agent_3.0209dd8896e41da62080.webp" alt="feature img" className="img-fluid" />
                                    </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="work-process ptb-120 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-[#ffffff] via-white to-[#ffffff]">
                <div className="max-w-7xl px-4 w-full  xl:px-9 lg:mx-auto undefined">
                    <div className="row">
                        <div className="col-md-8 align-items-center justify-content-between text-center mx-auto">
                            <h2 className="text-card-primary font-bold tracking-tight font-arimo jello-horizontal text-4xl md:text-5xl">Connect, Play, Earn and Relax in UtoposVerse</h2>
                            <p className="text-dark">Welcome to Utopos, the ultimate destination for a futuristic and exciting lifestyle! Our blockchain city offers a unique blend of ecommerce, gaming, dating, innovation, social networking, leisure, and education - all in one place.</p>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between py-10">
                        <div className="col-lg-5 col-md-12 order-1 order-lg-0">
                            <div className="img-wrap" data-aos="fade-up" data-aos-delay="50">
                            <img src={econ} alt="work process" className="img-fluid rounded-custom" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 order-0 order-lg-1">
                            <h2 className="text-card-primary font-bold tracking-tight font-arimo jello-horizontal text-2xl md:text-3xl">Online Shopping</h2>
                            <p className="text-dark pt-2">Online Shopping Are you a fan of online shopping? Look no further than Utopos, where our state-of-the-art ecommerce platform allows you to browse, shop and pay with ease, all powered by our UTOP token.</p>
                            <div className="mt-10 flex items-center justify-cd gap-x-6">
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6 col-md-12 order-0 order-lg-0">
                            <h2 className="text-card-primary font-bold tracking-tight font-arimo jello-horizontal text-2xl md:text-3xl">Connect, Date &amp; Socialize</h2>
                            <p className="text-dark pt-2">Need a break from the hustle and bustle? Connect with like-minded individuals in our dating platform, or socialize with friends in UtoposVerse, our social networking site dedicated solely to the community’s needs and interests.</p>
                            <div className="mt-10 flex items-center justify-cd gap-x-6">
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 order-1 order-lg-1">
                            <div className="img-wrap" data-aos="fade-up" data-aos-delay="50">
                                <img src={econ} alt="work process" className="img-fluid rounded-custom" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5">
                            <img src={iot} alt="feature img" className="img-fluid" />
                        </div>
                        <div className="col-lg-6 text-dark">
                            <h2 className="text-card-primary font-bold tracking-tight font-arimo jello-horizontal text-2xl md:text-3xl">Internet of Things fueled by AI, blockchain, VR, and robotic technologies</h2>
                            <p>For the innovative minds out there, Utopos is a testbed for developing a comprehensive Internet of Things covering an entire urban area, all fueled by AI, blockchain, VR, and robotic technologies. And for those seeking knowledge and education, our platform offers access to top-notch resources and experts in various fields, giving you the opportunity to learn and grow alongside our ever-evolving city.</p>
                        </div>
                        <div className="row  py-20">
                            <div className="col-md-9 mx-auto text-center my-10">
                                <h3 className="text-card-primary text-4xl font-bold">Join us today and experience the excitement of Utopos - where the possibilities are endless!</h3>
                                <div className="mt-6">
                                    <a href="#" className="rounded-xl jello-horizontal Utopos_btn px-8 py-2.5 text-md font-semibold leading-7 font-arimo text-card uppercase shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        Coming Soon
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work-process ptb-120 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-white via-white to-[#effdf5]">
                <Container>

                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 order-1 order-lg-0">
                            <div className="img-wrap" data-aos="fade-up" data-aos-delay="50">
                                <img src={agent_3} alt="work process" className="img-fluid rounded-custom" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 order-0 order-lg-1">
                            <h2 className="text-card-primary font-bold tracking-tight font-arimo jello-horizontal text-4xl md:text-5xl">Connect, Play, Earn and Relax in Utopverse</h2>
                            <p className="text-dark py-4">Utopverse is the first ever virtual universe where you can play games, relax and socialize with
                                friends. UtopVerse is a social networking site dedicated solely to the community’s needs and
                                interests. We are constantly working on making it better for you and your friends.</p>
                            <div className="mt-10 flex items-center justify-cd gap-x-6">
                                <a
                                    href="#"
                                    className="rounded-xl text-white jello-horizontal bg-spring px-8 py-2.5 text-md font-semibold leading-7 font-arimo text-forest uppercase shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Try Utopverse
                                </a>

                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="work-process ptb-120 bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-white via-white to-[#ece7ff]">
                <div className="max-w-7xl px-4 w-full  xl:px-9 lg:mx-auto undefined">
                    <div className="row">
                        <div className="bg-dark/10 py-8 col-12 ">
                            <h2 className="text-card-primary font-bold tracking-tight font-arimo jello-horizontal text-2xl md:text-5xl mb-6 text-center">Frequently Asked Questions</h2>
                            <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-2">
                                <div className="lg:flex -mx-4">
                                    <div className="lg:w-1/2 px-2">
                                        <dl className="mt-6 space-y-6 divide-y divide-gray-800">
                                            <div>
                                                <dt className="text-lg leading-6 font-medium text-card-secondary">What is UtoposVerse?</dt>
                                                <dd className="mt-2 text-base text-gray-500">UtoposVerse is a virtual reality platform that is mapped to the real world, allowing users to engage in real-time experiences and interactions with each other.
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="text-lg leading-6 font-medium text-card-secondary mt-3">What is the UTOP token used for in UtoposVerse?
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">The UTOP token is used as the main means of land acquisition and real estate construction within the Utopos blockchain city. It can also be used for e-commerce, payments, and all daily financial transactions within the Utopos ecosystem.
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="text-lg leading-6 font-medium text-card-secondary mt-3">How does UtoposVerse differ from other virtual reality platforms?
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">UtoposVerse stands out from other virtual reality platforms as it is built on Polygon (Matic) and offers a decentralized, safe, and fast metaverse where users can actually own their digital content.
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="text-lg leading-6 font-medium text-card-secondary mt-3">How can users earn UTOP tokens in UtoposVerse?</dt>
                                                <dd className="mt-2 text-base text-gray-500">Users can earn UTOP tokens through multiple income streams, such as earning a return on their properties, participating in Utopos bounties, collecting fees from visitors, creating classrooms, hosting live shows, and scheduling meetings with nearby business owners.
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="text-lg leading-6 font-medium text-card-secondary mt-3">What are the benefits of owning real estate in UtoposVerse?
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">Owning real estate in UtoposVerse offers users the ability to build on their property, sell it, and even purchase real-world locations in Utopos city from their UtoposVerse account.
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="text-lg leading-6 font-medium text-card-secondary mt-3">Will there be any job opportunities for the younger generation in the UtoposVerse?
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">Yes, UtoposVerse is creating new job opportunities for the younger generation in areas such as virtual real estate development, programming, content creation, and customer service, among others.
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                    <div className="lg:w-1/2 px-4">
                                        <dl className="mt-6 space-y-6 divide-y divide-gray-800">
                                            <div>
                                                <dt className="text-lg leading-6 font-medium text-card-secondary mt-3">Can UtoposVerse be used for educational purposes?
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">Yes, UtoposVerse can be used for educational purposes as users can create classrooms and host virtual lessons within the platform.</dd>
                                            </div>
                                            <div>
                                                <dt className="text-lg leading-6 font-medium text-card-secondary mt-3">How does UtoSwap simplify crypto portfolio management?
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">UtoSwap offers a decentralized exchange where users can buy, sell, and earn over 200 top cryptocurrencies with low fees and fast trading, making it easy to manage a crypto portfolio.
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="text-lg leading-6 font-medium text-card-secondary mt-3">What is the distribution of UTOP tokens?
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">UTOP tokens are the native cryptocurrency of Utopos blockchain city and represent ownership and value of a community member's stakes in real estate and other assets created within Utopos.
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="text-lg leading-6 font-medium text-card-secondary mt-3">Can UtoposVerse be used for socializing with friends?
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">Yes, UtoposVerse offers a social networking site dedicated solely to the community’s needs and interests, allowing users to connect, play, and earn UTOP tokens with friends.
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="text-lg leading-6 font-medium text-card-secondary mt-3">What are the future plans for UtoposVerse?
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">UtoposVerse plans to continue improving the platform for its users, integrating advanced technologies such as AI, and expanding its virtual universe to offer even more experiences and opportunities for its community.
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="text-lg leading-6 font-medium text-card-secondary mt-3">How will UtoposVerse change the future of education for the younger generation?
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">UtoposVerse will provide a new dimension of immersive learning experiences for the younger generation, allowing them to learn through virtual reality simulations and real-life examples, leading to better retention and understanding of concepts.
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}