import Header from "../components/Layout//Header_02";
import Hero from "../components/Exchange/HeroSection";
import Content from "../components/Exchange/Content";
import Footer from "../components/Layout/Footer";

export default function Home() {
  return (
    <>
      <Header />
      <Hero />
      <Content />
      <Footer />
    </>
  );
}
